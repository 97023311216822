import React from "react";
import Logo from "../assets/img/logo_bg.jpg";
import "../assets/css/Parallax.css";

const Header = () => {
  return (
    <div className="bg-blue-700 px-5 pb-10 min-h-screen" id="home">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div id="stars3"></div>
      <div className="container mx-auto px-4 lg:px-56 h-screen flex flex-col justify-center items-center">
        <div className="w-44 h-44 md:w-64 md:h-64">
          <img src={Logo} alt="Logo" className="rounded-full w-full h-full object-cover" />
        </div>

        <div className="brand justify-center text-white text-center mt-10 lg:mt-0">
          <div className="event-name text-5xl lg:text-7xl font-bold">
            PIPTK <span className="text-pink-600  font-semibold">2023</span>
          </div>

          <div className="event-theme pt-5 text-lg lg:text-xl">
            Membentuk Generasi yang Cendikia dan Inovatif <br className="hidden lg:block" />
            Bersinergi Memajukan Intelektual Nasional
          </div>
          <div className="regist-button pt-5 ">
            <a href="#countdown" className="inline-block bg-cyan-500 hover:bg-pink-600 mt-3 px-6 py-3 rounded-md text-white w-full md:w-auto text-center font-semibold">
              Daftar!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
