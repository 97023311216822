import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const countdownDate = new Date("March 31, 2023 00:00:00").getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      setTimeLeft(distance);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  return (
    <div className="flex flex-col items-center justify-center min-h-full md:min-h-screen bg-blue-900 min-w-full pb-5" id="countdown">
      <div className="container mx-auto text-center">
        <div className="countdown-event text-5xl font-bold pd-52 mt-24 sm:mt-56">Terima Kasih Sudah Mendaftar!</div>
        <div className="countdown-time flex text-xl md:text-2xl justify-center mt-16 mx-auto">Kami Tunggu di Kampus PoltekSSN</div>
        <div className="flex flex-col items-center justify-center mt-12 sm:flex-row sm:justify-center">
          <div className="regist-button pt-5 pr-0 sm:pr-10">
            <a href="/guidebook" class="inline-block bg-cyan-500 hover:bg-pink-600 mt-3 px-6 py-3 rounded-md text-white">
              Download Guidebook
            </a>
          </div>
          {/* <div className="regist-button pt-5 sm:ml-8">
            <a href="/registrasi" class="inline-block bg-cyan-500 hover:bg-pink-600 mt-3 px-6 py-3 rounded-md text-white">
              Daftar Sekarang!
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Countdown;
