import React, { useEffect, useRef } from "react";

const Map = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const mapScript = document.createElement("script");
    mapScript.src = "https://1map.com/js/script-for-user.js?embed_id=882550";
    mapScript.async = true;
    mapScript.onload = () => {
      window.OneMap.initMap({
        height: "200vh",
        width: "250vw",
        zoom: 17,
        queryString: "Politeknik Siber dan Sandi Negara, Jalan Raya Haji Usa, Putat Nutug, Bogor Regency, West Java, Indonesia",
        place_id: "ChIJOWKJBLvnaS4Raju5wagkb4o",
        satellite: false,
        centerCoord: [-6.456868701797203, 106.66862239999998],
        cid: "0x8a6f24a8c1b93b6a",
        lang: "en",
        cityUrl: "/indonesia/jakarta",
        cityAnchorText: "Map of Jakarta, Java, Indonesia",
        id: "map-9cd199b9cc5410cd3b1ad21cab2e54d3",
        embed_id: "882550",
      });
    };
    document.body.appendChild(mapScript);

    return () => {
      document.body.removeChild(mapScript);
    };
  }, []);

  return (
    <div id="wrapper-9cd199b9cc5410cd3b1ad21cab2e54d3" className="px-5">
      <div ref={mapRef} id="map-9cd199b9cc5410cd3b1ad21cab2e54d3"></div>
    </div>
  );
};

export default Map;
