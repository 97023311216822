import React, { useState, useEffect } from "react";
import ReactTypingEffect from "react-typing-effect";
import CustomizedTimeline from "./TimelineComp";
import Header from "./Header";
import About from "./About";
import Countdown from "./Countdown";
import Competitions from "./Competition";
import TopNavbar from "./Top-navbar";
import Footer from "./footer";

const Main = () => {
  return (
    <div>
      <TopNavbar />
      <Header />
      <About />
      <CustomizedTimeline />
      <Competitions />
      <Countdown />
      <Footer />
    </div>
  );
};

export default Main;
