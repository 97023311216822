import React, { useState } from "react";
import { Link } from "react-router-dom";

const TopNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="flex items-center justify-between flex-wrap bg-blue-900 p-6 min-w-full">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <span className="font-semibold text-2xl tracking-tight">
          <Link to="/">PIPTK 2023</Link>
        </span>
      </div>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white" onClick={toggleMenu}>
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} w-full block lg:flex lg:items-center lg:w-auto ml-5`}>
        <div className="text-sm lg:flex-grow">
          <a href="#home" className="block text-lg mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4" onClick={toggleMenu}>
            Home
          </a>
          <a href="#kompetisi" className="block text-lg mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4" onClick={toggleMenu}>
            Competition
          </a>
          <a href="/guidebook" className="block text-lg mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4" onClick={toggleMenu}>
            Guidebook
          </a>
          <a href="#about" className="block text-lg mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white" onClick={toggleMenu}>
            About
          </a>
        </div>
      </div>
    </nav>
  );
};

export default TopNavbar;
