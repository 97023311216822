import React from "react";
import LogoSen from "../assets/img/senkorpstar.png";
import LogoFMKI from "../assets/img/fmki.png";

const About = () => {
  return (
    <div className="container text-center pt-16 px-6 md:px-48 mx-auto bg-blue-500 min-h-full md:min-h-screen min-w-full pb-5" id="about">
      <div className="title text-3xl md:text-5xl font-extrabold">
        About
        <br />
        <span className="text-white font-semibold">PIPTK 2023</span>
      </div>
      <div className="desc text-white text-justify text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-4 pt-10">
        Pekan Ilmiah Perguruan Tinggi Kedinasan 2023 merupakan kompetisi di bidang akademik yang dapat diikuti oleh seluruh mahasiswa perguruan tinggi kedinasan di Indonesia. Kegiatan ini menjadi wadah untuk mempererat hubungan dan meningkatkan kemampuan akademik mahasiswa/taruna/praja perguruan tinggi kedinasan. Kegiatan PIPTK 2023 terdiri dari <span className="font-bold">sepuluh cabang lomba</span> yang akan dilaksanakan melalui media daring untuk babak penyisihan dan luring pada babak final. Babak final PIPTK 2023 akan diadakan di Kampus <span className="font-bold">Politeknik Siber dan Sandi Negara.</span>
      </div>
      <div className="collab text-white pt-10">
        <div className="collab-text py-4 md:py-10 text-sm md:text-lg font-bold">in collaboration with</div>
        <div className="collab-logo flex flex-row md:flex-row justify-center space-x-10">
          <a href="https://korpstar-poltekssn.org/">
            <img src={LogoSen} alt="Logo" className="h-28 md:h-44 w-28 md:w-44 mr-0 md:mr-10 mb-4 md:mb-0" />
          </a>
          <a href="https://fmkipusat.com/">
            <img src={LogoFMKI} alt="Logo" className="h-24 md:h-40 w-24 md:w-40" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
