import React, { useState } from "react";
import { Link } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import CreateIcon from "@mui/icons-material/Create";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import MicIcon from "@mui/icons-material/Mic";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import BoyIcon from "@mui/icons-material/Boy";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import TranslateIcon from "@mui/icons-material/Translate";

const Competitions = () => {
  const [competitions, setCompetitions] = useState([
    {
      id: 1,
      name: "Esai",
      date: "Esai adalah jenis karangan prosa yang membahas gagasan secara argumentatif dan menjadi simpulan pembaca untuk menyelesaikan permasalahan sejenis. Lomba Esai PIPTK 2023 mengangkat tema “Gagasan Mahasiswa Kedinasan untuk Mengatasi Keadaan Darurat Global.",
      location: <DescriptionIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 2,
      name: "Karya Tulis Ilmiah",
      date: "Lomba Karya Tulis Ilmiah mendorong mahasiswa kedinasan untuk membuat karya objektif, rasional, faktual, dan aktual dengan menerapkan kaidah ilmiah, serta mengimplementasikan hasil pemikiran dan pengetahuannya untuk bermanfaat bagi masyarakat.",
      location: <CreateIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 3,
      name: "Kedinasan Periksa Fakta",
      date: "Lomba Kedinasan Periksa Fakta adalah ajang baru di PIPT 2023 yang bertujuan melahirkan generasi muda yang kritis dan peka fakta di era informasi. Lomba ini bertujuan untuk memeriksa kebenaran informasi termasuk fakta atau hoaks agar mampu meminimalisir penyebaran informasi yang tidak benar di media sosial.",
      location: <FindInPageIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 4,
      name: "Pidato",
      date: "Pidato merupakan salah satu cabang lomba yang diadakan dalam rangkaian acara PIPTK 2023. Pidato bersifat dua arah, yaitu pembicara harus memperhatikan lawan bicaranya walaupun pembicara lebih banyak mendominasi pembicaraan. Tema lomba pidato yakni ”Kreativitas dan Inovasi Menyongsong Indonesia Emas 2045",
      location: <MicIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 5,
      name: "English Speech",
      date: "English Speech adalah cabang perlombaan di PIPTK 2023 yang dapat mengasah kemampuan public speaking dan bahasa Inggris serta melatih kepercayaan diri. Lomba ini juga menjadi wadah bagi peserta untuk menunjukkan kreativitas dan merealisasikan ide ke dalam pidato mereka.",
      location: <SpatialAudioOffIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 6,
      name: "Story Telling",
      date: 'Story telling adalah lomba yang memungkinkan mahasiswa kedinasan untuk mengekspresikan kemampuan bahasa Inggris dan memperkaya wawasan tentang budaya Indonesia. Lomba ini mengangkat tema "Character Development Through the Diversity of Indonesian Stories" dengan tujuan untuk meningkatkan kemampuan bahasa Inggris serta melestarikan budaya Indonesia.',
      location: <BoyIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 7,
      name: "Cerdas Cermat Kebangsaan",
      date: "Cerdas Cermat Kebangsaan merupakan kompetisi uji wawasan kebangsaan dalam bentuk cerdas cermat. Tujuan dari perlombaan ini adalah untuk menguji pengetahuan terkait Negara Kesatuan Republik Indonesia. Cerdas Cermat Kebangsaan PIPTK 2023 mengangkat tema “Meningkatkan Wawasan Kebangsaan dan Cinta Tanah Air Indonesia”.",
      location: <PsychologyIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 8,
      name: "English Debate Competition",
      date: "English Debate Competition adalah suatu kompetisi dalam bentuk diskusi yang berisikan argumen atau ide. Peserta mendiskusikan suatu topik dari dua sisi berbeda baik mendukung dan menolak pernyataan. EDC mengangkat tema ”Speak Up Your Idea’s to Achieve Golden Indonesia 2045” dimana peserta menanggapi berbagai isu baik skala nasional maupun internasional.",
      location: <ConnectWithoutContactIcon sx={{ fontSize: 80 }} />,
    },
    {
      id: 9,
      name: "Debat Bahasa Indonesia",
      date: "Lomba debat bahasa Indonesia menggunakan sistem parlemen Asia atau Asian Parliamentary Debate. Kegiatan dilaksanakan secara offline oleh 16 tim terbaik yang dieliminasi dari pengumpulan video argumen masing-masing tim. Pemenang diambil dari akumulasi poin tertinggi setiap tim.",
      location: <Diversity3Icon sx={{ fontSize: 80 }} />,
    },
    {
      id: 10,
      name: "Scrabble",
      date: "Scrabble PIPTK 2023 adalah kompetisi menyusun kosakata dari ubin huruf berisikan poin di atas papan permainan berkotak. Tujuannya adalah untuk meningkatkan penguasaan English vocabulary dan memacu kemampuan intelektual melalui berpikir kritis, kreatif, dan evaluatif.",
      location: <TranslateIcon sx={{ fontSize: 80 }} />,
    },
  ]);

  return (
    <div className="bg-gray-100 h-max" id="kompetisi">
      <div className="container mx-auto">
        <h1 className="text-6xl font-bold text-center pt-16 pb-5 ">Kompetisi</h1>
        <div className="card-container flex flex-wrap -mx-2 w-full justify-around">
          {competitions.map((competition) => (
            <div key={competition.id} className="card w-full sm:w-1/2 lg:w-1/3 px-2 mb-4 h-[450px] ">
              <div className="bg-white rounded-lg shadow-md h-full grid grid-flow-row">
                <div className="p-6">
                  <div className="header h-32">
                    <div className="icon mx-auto flex items-center justify-center ">{competition.location}</div>
                    <h2 className="name text-2xl font-bold mb-10 text-center">{competition.name}</h2>
                  </div>
                  <div className="mt-10">
                    <p className="desc text-gray-700 text-justify">{competition.date}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="regist-button pt-10 pb-16 mx-auto text-center ">
          <a href="/dokumen" class="inline-block bg-cyan-500 hover:bg-pink-600 px-6 py-3 rounded-md text-white">
            Format Dokumen
          </a>
        </div>
      </div>
    </div>
  );
};

export default Competitions;
