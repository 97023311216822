import React from "react";
import "../assets/css/Footer.css";
import Map from "./Map";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";

const Footer = () => {
  return (
    <div>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="footer-col">
              <h4>Kontak Kami</h4>
              <ul>
                <li>
                  <a href="https://goo.gl/maps/9wvtLSRcyffWHue5A">Jl. Raya H. Usa, Putat Nutug, Kec. Ciseeng, Kabupaten Bogor, Jawa Barat 16120, Indonesia</a>
                  <br />
                  <a href="https://wa.me/6287837284168">
                    <WhatsAppIcon /> Faza
                  </a>
                  <a href="https://t.me/fadelazzahraa">
                    <TelegramIcon /> Faza
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer-col">
              <h4>Follow us on</h4>
              <div class="social-media">
                <a href="https://instagram.com/piptk.official?igshid=YmMyMTA2M2Y=">
                  <InstagramIcon />
                </a>
              </div>
            </div>
            <div>
              <Map />
            </div>
          </div>
        </div>
      </footer>
      <div class="fixed-footer">
        <div class="container">Copyright &copy; Teknis PIPTK 2023</div>
      </div>
    </div>
  );
};

export default Footer;
