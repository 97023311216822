import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Main from "./components/Main";

import Dashboard from "./components/Dashboard";
import TopNavbar from "./components/Top-navbar";
import Footer from "./components/footer";
import Competitions from "./components/Competition";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Main} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
