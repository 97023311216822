import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CloseIcon from "@mui/icons-material/Close";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import TourIcon from "@mui/icons-material/Tour";

export default function CustomizedTimeline() {
  return (
    <div className="container bg-blue-700 min-w-full min-h-full py-20 mx-auto" id="timeline">
      <div className="title font-bold text-center text-6xl pt-5 pb-8">Timeline</div>
      <div>
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0", mr: 3 }} align="right" variant="body2" color="white" fontSize="16px">
              19 Februari 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ py: 1 }} />
              <TimelineDot color="secondary">
                <AppRegistrationIcon sx={{ fontSize: 40 }} />
              </TimelineDot>
              <TimelineConnector sx={{ py: 1 }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: "auto 0", ml: 3 }} align="right" variant="body2" color="white" fontSize="18px">
              Pembukaan Pendaftaran
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0", ml: 3 }} align="right" variant="body2" color="white" fontSize="16px">
              30 Maret 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ py: 1 }} />
              <TimelineDot color="primary">
                <CloseIcon sx={{ fontSize: 40 }} />
              </TimelineDot>
              <TimelineConnector sx={{ py: 1 }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: "auto 0", mr: 3 }} align="right" variant="body2" color="white" fontSize="18px">
              Penutupan Pendaftaran
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0", mr: 3 }} align="right" variant="body2" color="white" fontSize="16px">
              20-21 Mei 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ py: 1 }} />
              <TimelineDot color="secondary">
                <HowToRegIcon sx={{ fontSize: 40 }} />
              </TimelineDot>
              <TimelineConnector sx={{ py: 1 }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: "auto 0", ml: 3 }} align="right" variant="body2" color="white" fontSize="18px">
              Penyisihan I (daring)
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0", ml: 3 }} align="right" variant="body2" color="white" fontSize="16px">
              27-28 Mei 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ py: 1 }} />
              <TimelineDot color="primary">
                <PeopleAltIcon sx={{ fontSize: 40 }} />
              </TimelineDot>
              <TimelineConnector sx={{ py: 1 }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: "auto 0", mr: 3 }} align="right" variant="body2" color="white" fontSize="18px">
              Penyisihan II (daring)
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0", mr: 3 }} align="right" variant="body2" color="white" fontSize="16px">
              30 Juni 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ py: 1 }} />
              <TimelineDot color="secondary">
                <EmojiPeopleIcon sx={{ fontSize: 40 }} />
              </TimelineDot>
              <TimelineConnector sx={{ py: 1 }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: "auto 0", ml: 3 }} align="right" variant="body2" color="white" fontSize="18px">
              Kedatangan Peserta (luring)
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0", ml: 3 }} align="right" variant="body2" color="white" fontSize="16px">
              1-2 Juli 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ py: 1 }} />
              <TimelineDot color="primary">
                <TourIcon sx={{ fontSize: 40 }} />
              </TimelineDot>
              <TimelineConnector sx={{ py: 1 }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: "auto 0", mr: 3 }} align="right" variant="body2" color="white" fontSize="18px">
              Grand Final PIPTK
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
}
